import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/hrbfpagelayout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      registrationUrl
      registrationCtaText
      headerColor
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`
const SystemRequirements = props => {
  let registerUrl
  if (typeof window !== "undefined") {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
    >
      <Head
        title={props?.data?.contentfulEvents?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />
      <div className="sysreq_main">
        <h2
          className="sysreq_title"
          style={{
            background: `-webkit-linear-gradient(top left, ${props?.data?.contentfulEvents?.headerColor})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          System Requirements
        </h2>

        <div className="sysreq_row">
          <h5>Which system requirements do I need to use the platform?</h5>
          <p>
            We recommend you connect to the event using the Chrome browser from
            an internet-enabled desktop or laptop.
          </p>
          <div className="sysreq_row_table">
            <table>
              <tr>
                <th>Desktop Browser & operating System</th>
                <th>Chrome</th>
                <th>Firefox</th>
                <th>Edge</th>
                <th>Safari</th>
                <th>IE</th>
              </tr>
              <tr>
                <td>Windows 7</td>
                <td>Minimum Version 80</td>
                <td>Minimum Version 73</td>
                <td>Edge Chromium</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
              </tr>
              <tr>
                <td>Windows 8.1</td>
                <td>Minimum Version 80</td>
                <td>Minimum Version 73</td>
                <td>Edge Chromium</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
              </tr>
              <tr>
                <td>macOS Mojave 10.14</td>
                <td>Minimum Version 80</td>
                <td>Minimum Version 73</td>
                <td>Edge Chromium</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
              </tr>
              <tr>
                <td>macOS Catalina 10.15</td>
                <td>Minimum Version 80</td>
                <td>Minimum Version 73</td>
                <td>Edge Chromium</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
              </tr>
              <tr>
                <td>Red Hat Enterprise Linux 7.4</td>
                <td>Minimum Version 80</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
                <td>Not Recommended</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="sysreq_row">
          <h5>Can I use my phone to connect to the event?</h5>
          <p>
            We recommend using a laptop or desktop to get the full functionality
            and experience. If needed, our sites are optimized to be viewed
            briefly on a mobile device.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>What if I need to connect from my iPad/iPhone?</h5>
          <p>
            If you need to connect from your iOS device we recommend using
            Chrome. However, if you wish to share your video during a live,
            interactive session, you must use the Safari web browser.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>What if I need to connect from my Android device?</h5>
          <p>
            If you need to connect from your Android device, we recommend using
            Chrome. You will need to enable audio and video to participate in
            live, interactive sessions.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>The platform doesn’t seem to be working. What can I do?</h5>
          <p>Please check the following:</p>
          <ul>
            <li>
              Check that your browser is up to date and that you are using one
              of the recommended browsers above.
            </li>
            <li>Check that you are on a solid internet connection.</li>
            <li>Check that your company VPN is turned off.</li>
            <li>
              Check if there is a company firewall and try to connect to Wi-Fi
              that is not behind a company firewall.
            </li>
          </ul>
        </div>
        <div className="sysreq_row">
          <h5>I connected through a VPN. Will I have issues?</h5>
          <p>
            It is possible that your VPN could block incoming or outgoing video
            and audio. If possible, turn off your VPN or connect via an
            alternate device not connected to a VPN. If this is not possible, we
            recommend working with your preferred IT professional to resolve the
            issue.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>I’m connected through a company firewall. Will I have issues?</h5>
          <p>
            It is possible that your company firewall could block incoming or
            outgoing video and audio. If possible, connect to the event on a
            different network or use your mobile phone. If this is not possible,
            we recommend working with your preferred IT professional to resolve
            the issue.
          </p>
        </div>
        <div className="sysreq_row">
          <h4>Participating in a Session</h4>
          <h5>How do I get to my session? </h5>
          <p>Your session can be accessed through the Agenda.</p>
        </div>
        <div className="sysreq_row">
          <h5>I missed a session. Can I watch the replay?</h5>
          <p>
            Some sessions may be able to be replayed or saved for replay in the
            session library
          </p>
        </div>
        <div className="sysreq_row">
          <h5>When I join the session I can't hear. What do I do?</h5>
          <p>
            Please make sure your speakers and volume are enabled and working.
            Some of the sessions are pre-recorded and require you to select Play
            on the video to view the content. In this case, you will not hear
            anyone speaking until you begin the pre-recorded video.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>How do I chat with others in the Discussion?</h5>
          <p>
            You must check-in to the session in order to join the discussion.
            You can check-in under the video using the Check-In button.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>How do I ask questions during Live Q&A?</h5>
          <p>
            You can ask questions during a pre-recorded or live session by
            posting a question in the Live Q&A tab. This tab is in the
            information tab of the session if it is active. In a collaborative
            setting, you can ask questions by raising your hand.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>
            What is the difference between the Live Q&A and the Discussion
            Board?
          </h5>
          <p>
            The Live Q&A is a place to ask the Presenter questions directly. The
            Discussion Board is used to pose questions and have conversations
            regarding the content with fellow attendees in the virtual session.
          </p>
        </div>
        <div className="sysreq_row">
          <h4>Audio/Video</h4>
          <h5>
            I accidentally clicked “Block” instead of “Allow” when asked to
            enable my camera and microphone. How do I enable this now?
          </h5>
          <p>
            To enable your camera and microphone in Google Chrome, click on the
            locked icon at the beginning of the URL, and select Allow for
            camera, notifications, and microphone.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>
            I am having trouble with the video quality and load time on the
            site.
          </h5>
          <p>
            Ensure that other devices on your network are not consuming
            bandwidth through downloads, streaming, updating, or watching online
            videos, as each of these will greatly slow down your connection.
          </p>
          <p>
            If you're using Windows, ensure that Windows Updates are not
            downloading in the background. To access this menu, simply press the
            Windows icon in the bottom left corner of your screen and type
            “update.” After selecting “Check for Updates” you will be able to
            verify whether your internet bandwidth is being taken by the Windows
            Update. If there is a download occurring, simply press the Pause
            button to stop the download and speed up your internet.
          </p>
          <p>
            If you're on a wireless device like a laptop or tablet, you may need
            to move closer to your network router to get a stronger signal. This
            can impact your internet speed greatly.
          </p>
        </div>
        <div className="sysreq_row">
          <h4>Miscellaneous</h4>
          <h5>
            My screen looks like it is cut off or the scrollbar is not working.
            How do I fix this?
          </h5>
          <p>
            Check that your cursor is hovering over the section you would like
            to scroll. If it is, make sure you are connected to strong Wi-Fi or
            are connected to a hardline. If your internet/Wi-Fi is working on
            other pages, reload the page. You may also need to adjust the Zoom
            settings to 100% so you are able to see everything in your computer
            frame.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>I do not see a logout button. How do I leave the meeting?</h5>
          <p>
            You can close out the browser window or click the Logout button on
            the left-hand navigation.
          </p>
        </div>
        <div className="sysreq_row">
          <h5>How do I get Technical Support during the event?</h5>
          <p>
            Use the Meeting Concierge/Technical Support button to access our
            live chat option.
          </p>
        </div>
        <div className="sysreq_row">
          <h4>Additional Support</h4>
          <h5>I am still having technical problems on the platform. Help!</h5>
          <p>
            We've got you covered! Use the navigation to chat with your
            MeetingPlay Concierge. Our team is ready and available to chat
            directly and help you with any questions or concerns.
          </p>
        </div>
      </div>
    </PageLayout>
  )
}
export default SystemRequirements
